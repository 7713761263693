import "./Region.css";
import React, { useState, useEffect, useRef } from "react";
import useInfo from "../../hooks/use-info";
import Dropdown from "./Dropdown";
import { AiOutlineClose } from "react-icons/ai";

const Region = () => {
  const { region, setRegion, left, setView, scrn, setRight, setLeft } =
    useInfo();
  const [exp, setExp] = useState(0);
  const divEl = useRef();
  ///////////////////////////////////////////////////////////////
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        if (!region.city) {
          setExp(0);
        } else {
          setExp(2);
        }
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [region.city]);
  ///////////////////////////////////////////////////////////////
  const cityOps = [
    [4200000000, "강원특별자치도", 128.289, 37.769, 7.8, 6.2],
    [4100000000, "경기도", 127.12539545666645, 37.53124364676945, 7.94, 6.8],
    [4800000000, "경상남도", 128.433107314944, 35.343118390130705, 8.2, 6.6],
    [4700000000, "경상북도", 128.73115512196532, 36.365255372475744, 7.6, 6.6],
    [
      2900000000,
      "광주광역시",
      126.84151330381565,
      35.15720778712823,
      10.55,
      8.5,
    ],
    [2700000000, "대구광역시", 128.56800585183348, 35.81483606306635, 9.8, 8],
    [
      3000000000,
      "대전광역시",
      127.39876811725995,
      36.34337188400683,
      10.1,
      8.6,
    ],
    [2600000000, "부산광역시", 129.03466708638542, 35.19757937409587, 9.8, 8],
    [1100000000, "서울특별시", 126.9938628723925, 37.56517071157842, 10.3, 8.5],
    [
      3600000000,
      "세종특별자치시",
      127.25163909085717,
      36.57105292487958,
      10.1,
      8.8,
    ],
    [3100000000, "울산광역시", 129.24953884903306, 35.5232500898863, 9.8, 8],
    [
      2800000000,
      "인천광역시",
      126.63137838706569,
      37.491400046907835,
      10.3,
      8.4,
    ],
    [4600000000, "전라남도", 126.93638045536467, 34.855359635444486, 7.8, 6.6],
    [4500000000, "전라북도", 127.07339599342025, 35.70223356369705, 8.3, 6.8],
    [
      5000000000,
      "제주특별자치도",
      126.56993937939774,
      33.36372747227503,
      9.4,
      7.6,
    ],
    [4400000000, "충청남도", 126.8832434585271, 36.52119659338811, 8.2, 6.6],
    [4300000000, "충청북도", 127.68506139253616, 36.63960900311635, 8.2, 6.8],
    [9000000000, " 기타", 127.5176755984492, 36.87856478678846, 5, 5],
  ];

  var countyOps = [];

  switch (region.city.cd) {
    case 4200000000:
      countyOps = [
        [4215000000, "강릉시", 128.832379, 37.709102, 10, 8.5],
        [4282000000, "고성군", 128.411156, 38.362754, 10, 8.5],
        [4217000000, "동해시", 129.055628, 37.50672, 11, 8.5],
        [4223000000, "삼척시", 129.121899, 37.277449, 9.5, 8.5],
        [4221000000, "속초시", 128.519548, 38.176031, 11, 8.5],
        [4280000000, "양구군", 128.000206, 38.175609, 10, 8.5],
        [4283000000, "양양군", 128.595104, 38.00451, 10, 8.5],
        [4275000000, "영월군", 128.500296, 37.204114, 10, 8.5],
        [4213000000, "원주시", 127.929525, 37.308223, 10, 8.5],
        [4281000000, "인제군", 128.264727, 38.0646, 9.5, 8.5],
        [4277000000, "정선군", 128.739063, 37.378686, 10, 8.5],
        [4278000000, "철원군", 127.398924, 38.239085, 10, 8.5],
        [4211000000, "춘천시", 127.739868, 37.889797, 10, 8.5],
        [4219000000, "태백시", 128.980073, 37.172312, 10, 8.5],
        [4276000000, "평창군", 128.48259, 37.556839, 9.4, 8.5],
        [4272000000, "홍천군", 128.074261, 37.745049, 9, 8.5],
        [4279000000, "화천군", 127.685166, 38.138426, 10, 8.5],
        [4273000000, "횡성군", 128.077067, 37.509142, 10, 8.5],
      ];
      break;
    case 4100000000:
      countyOps = [
        [4182000000, "가평군", 127.450192, 37.818433, 9.4, 8.7],
        [4128100000, "고양시덕양구", 126.878632, 37.65581, 11, 9.5],
        [4128500000, "고양시일산동구", 126.79734, 37.679783, 11, 9.5],
        [4128700000, "고양시일산서구", 126.727858, 37.680327, 11, 9.5],
        [4129000000, "과천시", 127.002679, 37.433845, 11.5, 9.5],
        [4121000000, "광명시", 126.864694, 37.445169, 11.5, 9.5],
        [4161000000, "광주시", 127.301176, 37.403091, 10, 9.5],
        [4131000000, "구리시", 127.13123, 37.599221, 11.5, 9.5],
        [4141000000, "군포시", 126.921135, 37.343483, 11.5, 9.5],
        [4157000000, "김포시", 126.626431, 37.681772, 10, 9.5],
        [4136000000, "남양주시", 127.243661, 37.66253, 10, 9.5],
        [4125000000, "동두천시", 127.077913, 37.916538, 11, 9.5],
        [4119000000, "부천시", 126.788711, 37.504258, 11.4, 9.5],
        [4113500000, "성남시분당구", 127.106064, 37.379302, 11.5, 9.5],
        [4113100000, "성남시수정구", 127.104145, 37.435166, 11.5, 9.5],
        [4113300000, "성남시중원구", 127.16391, 37.433433, 11.5, 9.5],
        [4111300000, "수원시권선구", 126.979758, 37.260525, 11.5, 9.5],
        [4111700000, "수원시영통구", 127.056728, 37.275019, 11.5, 9.5],
        [4111100000, "수원시장안구", 127.003452, 37.313966, 11.5, 9.5],
        [4111500000, "수원시팔달구", 127.016239, 37.277464, 11.5, 9.5],
        [4139000000, "시흥시", 126.783964, 37.386237, 10, 9.5],
        [4127300000, "안산시단원구", 126.694041, 37.281925, 11, 9.5],
        [4127100000, "안산시상록구", 126.870815, 37.316004, 11, 9.5],
        [4155000000, "안성시", 127.302722, 37.035027, 10, 9.5],
        [4117300000, "안양시동안구", 126.955503, 37.400392, 11.5, 9.5],
        [4117100000, "안양시만안구", 126.911378, 37.404136, 11.5, 9.5],
        [4163000000, "양주시", 127.001135, 37.808658, 10, 9.5],
        [4183000000, "양평군", 127.579264, 37.518056, 10, 8.5],
        [4167000000, "여주시", 127.615737, 37.302458, 10, 9.5],
        [4180000000, "연천군", 127.024456, 38.09273, 10, 8.5],
        [4137000000, "오산시", 127.05133, 37.163291, 11.5, 9.5],
        [4146300000, "용인시기흥구", 127.121341, 37.267432, 11, 9.5],
        [4146500000, "용인시수지구", 127.071551, 37.333447, 11.3, 9.5],
        [4146100000, "용인시처인구", 127.252933, 37.203332, 10.4, 9.5],
        [4143000000, "의왕시", 126.989625, 37.362388, 11.5, 9.5],
        [4115000000, "의정부시", 127.068423, 37.736192, 11.5, 9.5],
        [4150000000, "이천시", 127.481014, 37.209776, 10, 9.5],
        [4148000000, "파주시", 126.810633, 37.856216, 10, 9.5],
        [4122000000, "평택시", 126.987664, 37.011825, 10, 9.5],
        [4165000000, "포천시", 127.250337, 37.969778, 10, 9.5],
        [4145000000, "하남시", 127.205944, 37.522814, 11.5, 9.5],
        [4159000000, "화성시", 126.874859, 37.165235, 10, 9.5],
      ];

      break;
    case 4800000000:
      countyOps = [
        [4831000000, "거제시", 128.623136, 34.870618, 10, 8.5],
        [4888000000, "거창군", 127.904169, 35.732552, 9.8, 8.5],
        [4882000000, "고성군", 128.290663, 35.016304, 10.45, 8.5],
        [4825000000, "김해시", 128.845216, 35.272156, 10.45, 8.5],
        [4884000000, "남해군", 127.941141, 34.818291, 10.45, 8.5],
        [4827000000, "밀양시", 128.75895, 35.492352, 10, 8.5],
        [4824000000, "사천시", 127.937696, 35.049703, 10.5, 8.5],
        [4886000000, "산청군", 127.784338, 35.368597, 10, 8.5],
        [4833000000, "양산시", 128.941041, 35.401888, 10.4, 8.5],
        [4872000000, "의령군", 128.177058, 35.392431, 10.4, 8.5],
        [4817000000, "진주시", 128.029801, 35.205158, 10, 8.5],
        [4874000000, "창녕군", 128.393052, 35.508228, 10, 8.5],
        [4812500000, "창원시마산합포구", 128.449611, 35.142588, 10.5, 8.5],
        [4812700000, "창원시마산회원구", 128.536433, 35.232227, 11.65, 8.5],
        [4812300000, "창원시성산구", 128.638104, 35.198873, 11.5, 8.5],
        [4812100000, "창원시의창구", 128.611792, 35.305763, 11.1, 8.5],
        [4812900000, "창원시진해구", 128.686301, 35.129949, 11, 8.5],
        [4822000000, "통영시", 128.359428, 34.869573, 10.5, 8.5],
        [4885000000, "하동군", 127.729049, 35.138305, 9.45, 8.5],
        [4873000000, "함안군", 128.380877, 35.291003, 10.5, 8.5],
        [4887000000, "함양군", 127.672041, 35.551602, 9.45, 8.5],
        [4889000000, "합천군", 128.091544, 35.576577, 9.45, 8.5],
      ];
      break;
    case 4700000000:
      countyOps = [
        [4729000000, "경산시", 128.809055, 35.83406, 10, 8.5],
        [4713000000, "경주시", 129.23593, 35.826643, 9, 8.5],
        [4783000000, "고령군", 128.30645, 35.736998, 10, 8.5],
        [4719000000, "구미시", 128.355555, 36.20731, 10, 8.5],
        [4715000000, "김천시", 128.077708, 36.060424, 10, 8.5],
        [4728000000, "문경시", 128.148608, 36.690792, 10, 8.5],
        [4792000000, "봉화군", 128.9129, 36.93414, 10, 8.5],
        [4725000000, "상주시", 128.067007, 36.429502, 10, 8.5],
        [4784000000, "성주군", 128.233393, 35.907223, 10, 8.5],
        [4717000000, "안동시", 128.780043, 36.580242, 9, 8.5],
        [4777000000, "영덕군", 129.317376, 36.482388, 10, 8.5],
        [4776000000, "영양군", 129.14503, 36.696389, 10, 8.5],
        [4721000000, "영주시", 128.597683, 36.870475, 10, 8.5],
        [4723000000, "영천시", 128.942624, 36.015778, 10, 8.5],
        [4790000000, "예천군", 128.422384, 36.653845, 10, 8.5],
        [4794000000, "울릉군", 130.864188, 37.50197, 10, 8.5],
        [4793000000, "울진군", 129.312349, 36.903897, 9, 8.5],
        [4773000000, "의성군", 128.615067, 36.362045, 10, 8.5],
        [4782000000, "청도군", 128.786527, 35.672975, 10, 8.5],
        [4775000000, "청송군", 129.057385, 36.356976, 10, 8.5],
        [4785000000, "칠곡군", 128.462584, 36.015511, 10, 8.5],
        [4711100000, "포항시남구", 129.437617, 35.958395, 10, 8.5],
        [4711300000, "포항시북구", 129.234053, 36.165055, 10, 8.5],
      ];
      break;
    case 2900000000:
      countyOps = [
        [2920000000, "광산구", 126.752895, 35.165035, 11, 9.6],
        [2915500000, "남구", 126.856718, 35.094058, 11, 9.6],
        [2911000000, "동구", 126.949464, 35.11738, 11, 9.6],
        [2917000000, "북구", 126.925486, 35.193246, 11, 9.6],
        [2914000000, "서구", 126.850719, 35.135693, 11.5, 9.6],
      ];
      break;
    case 2700000000:
      countyOps = [
        // [2772000000, "군위군", 128.648225, 36.170112, 10, 8.5],
        [2720000000, "남구", 128.58533, 35.835177, 12.5, 9.7],
        [2729000000, "달서구", 128.529212, 35.827483, 11.5, 9.7],
        [2771000000, "달성군", 128.498226, 35.759716, 10, 9],
        [2714000000, "동구", 128.68566, 35.934426, 11, 9.7],
        [2723000000, "북구", 128.577204, 35.928922, 11.5, 9.7],
        [2717000000, "서구", 128.549698, 35.875002, 12.5, 9.7],
        [2726000000, "수성구", 128.661274, 35.833849, 12, 9.7],
        [2711000000, "중구", 128.593606, 35.866535, 13.5, 11],
      ];
      break;
    case 3000000000:
      countyOps = [
        [3023000000, "대덕구", 127.440152, 36.412179, 11.5, 9.7],
        [3011000000, "동구", 127.475037, 36.32392, 10.5, 9.7],
        [3017000000, "서구", 127.345104, 36.28024, 11, 9.7],
        [3020000000, "유성구", 127.333256, 36.37679, 10.5, 9.7],
        [3014000000, "중구", 127.411057, 36.28086, 11, 9.7],
      ];
      break;
    case 2600000000:
      countyOps = [
        [2644000000, "강서구", 128.892401, 35.138348, 11, 9.5],
        [2641000000, "금정구", 129.091529, 35.258894, 12, 9.5],
        [2671000000, "기장군", 129.200956, 35.298012, 11, 9.5],
        [2629000000, "남구", 129.094307, 35.125548, 12, 9.5],
        [2617000000, "동구", 129.044586, 35.129186, 13, 9.5],
        [2626000000, "동래구", 129.07922, 35.206212, 12.6, 9.5],
        [2623000000, "부산진구", 129.04306, 35.165244, 12.1, 9.5],
        [2632000000, "북구", 129.023463, 35.229248, 12, 9.5],
        [2653000000, "사상구", 128.986592, 35.158031, 12, 9.5],
        [2638000000, "사하구", 128.973808, 35.088879, 12, 9.5],
        [2614000000, "서구", 129.014913, 35.102958, 12, 9.5],
        [2650000000, "수영구", 129.111202, 35.161308, 12.5, 9.5],
        [2647000000, "연제구", 129.082935, 35.182418, 12.5, 9.5],
        [2620000000, "영도구", 129.064823, 35.07865, 12, 9.5],
        [2611000000, "중구", 129.03154, 35.105478, 13.5, 9.5],
        [2635000000, "해운대구", 129.15359, 35.193855, 11.5, 9.5],
      ];
      break;
    case 1100000000:
      countyOps = [
        [1168000000, "강남구", 127.062985, 37.496644, 12, 10.7],
        [1174000000, "강동구", 127.147012, 37.55045, 12, 10.7],
        [1130500000, "강북구", 127.011189, 37.643474, 12, 10.7],
        [1150000000, "강서구", 126.822807, 37.561235, 12, 10.7],
        [1162000000, "관악구", 126.945337, 37.467376, 12, 10.7],
        [1121500000, "광진구", 127.085744, 37.54672, 12, 10.7],
        [1153000000, "구로구", 126.856301, 37.494405, 12, 10.7],
        [1154500000, "금천구", 126.90082, 37.460568, 12, 10.7],
        [1135000000, "노원구", 127.075035, 37.652511, 12, 10.7],
        [1132000000, "도봉구", 127.032369, 37.669102, 12, 10.7],
        [1123000000, "동대문구", 127.054848, 37.581957, 12, 10.7],
        [1159000000, "동작구", 126.951641, 37.498877, 12, 10.7],
        [1144000000, "마포구", 126.90827, 37.559313, 12, 10.7],
        [1141000000, "서대문구", 126.939063, 37.577785, 12, 10.7],
        [1165000000, "서초구", 127.03122, 37.473295, 12, 10.7],
        [1120000000, "성동구", 127.041059, 37.55103, 12, 10.7],
        [1129000000, "성북구", 127.017579, 37.605702, 12, 10.7],
        [1171000000, "송파구", 127.115295, 37.505619, 12, 10.7],
        [1147000000, "양천구", 126.855478, 37.524789, 12.5, 10.7],
        [1156000000, "영등포구", 126.910169, 37.522308, 12, 10.7],
        [1117000000, "용산구", 126.979907, 37.531385, 12, 10.7],
        [1138000000, "은평구", 126.927023, 37.619211, 12, 10.7],
        [1111000000, "종로구", 126.977321, 37.594917, 12, 10.7],
        [1114000000, "중구", 126.995968, 37.560144, 12, 10.7],
        [1126000000, "중랑구", 127.092883, 37.597817, 12, 10.7],
      ];
      break;
    case 3600000000:
      countyOps = [
        [
          3611000000,
          "세종특별자치시",
          127.25163909085717,
          36.57105292487958,
          10.1,
          9.4,
        ],
      ];
      break;
    case 3100000000:
      countyOps = [
        [3114000000, "남구", 129.328202, 35.51605, 11.4, 9.5],
        [3117000000, "동구", 129.426066, 35.525574, 11.4, 9.5],
        [3120000000, "북구", 129.379811, 35.610054, 10.9, 9.5],
        [3171000000, "울주군", 129.187694, 35.546079, 9.8, 8.5],
        [3111000000, "중구", 129.308243, 35.571049, 12, 9.5],
      ];
      break;
    case 2800000000:
      countyOps = [
        [2871000000, "강화군", 126.402085, 37.712441, 10.3, 9],
        [2824500000, "계양구", 126.7347, 37.557297, 12, 9.5],
        [2820000000, "남동구", 126.726589, 37.431211, 11.4, 9.5],
        [2814000000, "동구", 126.639033, 37.483339, 12.5, 9.5],
        // [2817700000, "미추홀구", 126.664655, 37.452595, 12, 9.5],
        [2823700000, "부평구", 126.721209, 37.496659, 12, 9.5],
        [2826000000, "서구", 126.655821, 37.557868, 10.9, 9.5],
        [2818500000, "연수구", 126.648563, 37.395921, 12, 9.5],
        // [2872000000, "옹진군", 125.636067, 37.543992, 12, 9.5],
        [2811000000, "중구", 126.482825, 37.469098, 11.3, 9.5],
      ];
      break;
    case 4600000000:
      countyOps = [
        [4681000000, "강진군", 126.772156, 34.62041, 10.2, 8.5],
        [4677000000, "고흥군", 127.31462, 34.598485, 10, 8.5],
        [4672000000, "곡성군", 127.263583, 35.216614, 10.4, 8.5],
        [4623000000, "광양시", 127.655066, 35.021027, 10, 8.5],
        [4673000000, "구례군", 127.503119, 35.236766, 10, 8.5],
        [4617000000, "나주시", 126.72041, 34.988589, 10, 8.5],
        [4671000000, "담양군", 126.995291, 35.291495, 10, 8.5],
        [4611000000, "목포시", 126.391835, 34.803764, 11, 8.5],
        [4684000000, "무안군", 126.425905, 34.953205, 10, 8.5],
        [4678000000, "보성군", 127.162121, 34.814375, 10, 8.5],
        [4615000000, "순천시", 127.389163, 34.994745, 10, 8.5],
        [4691000000, "신안군", 125.548907, 34.812407, 8.1, 8.5],
        [4613000000, "여수시", 127.653256, 34.698569, 10, 8.5],
        [4687000000, "영광군", 126.453129, 35.278486, 10, 8.5],
        [4683000000, "영암군", 126.630694, 34.799567, 10, 8.5],
        [4689000000, "완도군", 126.776807, 34.295579, 10, 8.5],
        [4688000000, "장성군", 126.768495, 35.329564, 10, 8.5],
        [4680000000, "장흥군", 126.921545, 34.676458, 9.4, 8.5],
        [4690000000, "진도군", 126.215058, 34.439396, 10, 8.5],
        [4686000000, "함평군", 126.535604, 35.112671, 10, 8.5],
        [4682000000, "해남군", 126.511715, 34.558672, 9.4, 8.5],
        [4679000000, "화순군", 127.033434, 35.008186, 10, 8.5],
      ];
      break;
    case 4500000000:
      countyOps = [
        [4579000000, "고창군", 126.616046, 35.448168, 10, 8.5],
        [4513000000, "군산시", 126.726015, 35.950439, 10, 8.5],
        [4521000000, "김제시", 126.894886, 35.806716, 10, 8.5],
        [4519000000, "남원시", 127.44189, 35.422545, 10, 8.5],
        [4573000000, "무주군", 127.712953, 35.939367, 10, 8.5],
        [4580000000, "부안군", 126.644356, 35.677918, 10, 8.5],
        [4577000000, "순창군", 127.090087, 35.433634, 10, 8.5],
        [4571000000, "완주군", 127.215115, 35.918614, 9.4, 8.5],
        [4514000000, "익산시", 126.98951, 36.023106, 10, 8.5],
        [4575000000, "임실군", 127.236647, 35.598202, 10, 8.5],
        [4574000000, "장수군", 127.544264, 35.657461, 10, 8.5],
        [4511300000, "전주시덕진구", 127.111753, 35.858323, 11, 8.5],
        [4511100000, "전주시완산구", 127.120764, 35.791249, 11, 8.5],
        [4518000000, "정읍시", 126.905858, 35.602625, 10, 8.5],
        [4572000000, "진안군", 127.430035, 35.828807, 9.5, 8.5],
      ];
      break;
    case 5000000000:
      countyOps = [
        [5013000000, "서귀포시", 126.581039, 33.324982, 9.5, 8.5],
        [5011000000, "제주시", 126.529248, 33.442202, 9.5, 8.5],
      ];
      break;
    case 4400000000:
      countyOps = [
        [4425000000, "계룡시", 127.234427, 36.291594, 11, 8.5],
        [4415000000, "공주시", 127.075219, 36.47982, 9.5, 8.5],
        [4471000000, "금산군", 127.478312, 36.119001, 10, 8.5],
        [4423000000, "논산시", 127.157716, 36.190887, 10, 8.5],
        [4427000000, "당진시", 126.652958, 36.903388, 10, 8.5],
        [4418000000, "보령시", 126.592957, 36.343284, 10, 8.5],
        [4476000000, "부여군", 126.856968, 36.246384, 10, 8.5],
        [4421000000, "서산시", 126.463602, 36.783993, 10, 8.5],
        [4477000000, "서천군", 126.704264, 36.106781, 10, 8.5],
        [4420000000, "아산시", 126.980076, 36.807316, 10, 8.5],
        [4481000000, "예산군", 126.78431, 36.670629, 10, 8.5],
        [4413100000, "천안시동남구", 127.220895, 36.764117, 10, 8.5],
        [4413300000, "천안시서북구", 127.161809, 36.892711, 10, 8.5],
        [4479000000, "청양군", 126.853113, 36.430583, 10, 8.5],
        [4482500000, "태안군", 126.280447, 36.707727, 10, 8.5],
        [4480000000, "홍성군", 126.62583, 36.570089, 10, 8.5],
      ];
      break;
    case 4300000000:
      countyOps = [
        [4376000000, "괴산군", 127.829588, 36.769659, 10, 8.5],
        [4380000000, "단양군", 128.387842, 36.994453, 10, 8.5],
        [4372000000, "보은군", 127.729336, 36.48995, 10, 8.5],
        [4374000000, "영동군", 127.814228, 36.159659, 10, 8.5],
        [4373000000, "옥천군", 127.656559, 36.320456, 10, 8.5],
        [4377000000, "음성군", 127.614207, 36.976223, 10, 8.5],
        [4315000000, "제천시", 128.140959, 37.059912, 10, 8.5],
        [4374500000, "증평군", 127.604618, 36.786473, 10.5, 8.5],
        [4375000000, "진천군", 127.440464, 36.871, 10, 8.5],
        [4311100000, "청주시상당구", 127.584882, 36.592113, 10, 8.5],
        [4311200000, "청주시서원구", 127.438403, 36.547265, 10.5, 8.5],
        [4311400000, "청주시청원구", 127.491318, 36.720579, 10.5, 8.5],
        [4311300000, "청주시흥덕구", 127.369275, 36.646961, 10.5, 8.5],
        [4313000000, "충주시", 127.895662, 37.015197, 10, 8.5],
      ];

      break;
    case 9000000000:
      countyOps = [
        [9999999999, "기타", 127.5176755984492, 36.87856478678846, 5, 5.6],
      ];
      break;

    default:
      break;
  }

  const handleCity = (code, str, long, lat, zm, zmsm) => {
    setRegion((prev) => {
      if (prev.city.cd === code) {
        return {
          city: { cd: null, name: null },
          county: { cd: null, name: null },
        };
      } else {
        setView({
          longitude: long,
          latitude: lat,
          zoom: scrn < 1015 ? zmsm : zm,
        });
        return {
          city: { cd: code, name: str },
          county: { cd: null, name: null },
        };
      }
    });
  };
  const handleCounty = (code, str, long, lat, zm, zmsm) => {
    setRegion((prev) => {
      if (prev.county.cd === code) {
        return { ...prev, county: { cd: null, name: null } };
      } else {
        setView({
          longitude: long,
          latitude: lat,
          zoom: scrn < 1015 ? zmsm : zm,
        });
        return {
          ...prev,
          county: { cd: code, name: str },
        };
      }
    });
  };

  const handleClose = () => {
    setExp(0);
    setRegion({
      city: { cd: null, name: null },
      county: { cd: null, name: null },
    });
  };
  const handleRgBtn = () => {
    if (scrn < 1015) {
      setRight(false);
      setLeft(false);
    }
    setExp(1);
  };
  ///////////////////////////////////////////////////////////////
  var rendered;

  switch (exp) {
    case 1:
      rendered = (
        <div
          ref={divEl}
          className={`regionExp ${left ? "" : "rmv_regionExp"} ${
            region.city.cd ? "exp1" : ""
          }`}
        >
          <div
            className={`region ${left ? "" : "rmv_region"}`}
            style={{
              border: "0px",
              top: `${scrn < 1015 ? "11px" : "15px"}`,
              left: `${scrn < 1015 && "61px"}`,
            }}
            onClick={handleRgBtn}
          >
            지역선택
          </div>
          <div className="city">
            <div className="reg_ttl">
              {region.city.cd ? region.city.name : "시/도"}
            </div>
            <Dropdown
              options={cityOps}
              handleOps={handleCity}
              type={"city_dd"}
            />
          </div>
          {region.city.cd && (
            <div className="county">
              <div className="reg_ttl">
                {region.county.cd ? region.county.name : "시/군/구"}
              </div>
              <Dropdown
                options={countyOps}
                handleOps={handleCounty}
                type={"coun_dd"}
              />
            </div>
          )}
          <AiOutlineClose
            className={`close ${region.city.cd ? "exp1" : ""}`}
            onClick={handleClose}
          />
        </div>
      );
      break;
    case 2:
      rendered = !region.city.cd ? (
        <div
          className={`region ${left ? "" : "rmv_region"}`}
          onClick={handleRgBtn}
        >
          지역선택
        </div>
      ) : (
        <div
          className={`regionExp ${left ? "" : "rmv_regionExp"} ${
            region.county.cd && scrn > 1015 ? "exp1" : ""
          } exp2`}
        >
          <div
            className={`region ${left ? "" : "rmv_region"}`}
            style={{
              border: "0px",
              top: `${scrn < 1015 ? "11px" : "15px"}`,
              left: `${scrn < 1015 && "61px"}`,
            }}
            onClick={handleRgBtn}
          >
            지역선택
          </div>
          <div className="city">
            <div className="reg_ttl">{region.city.name}</div>
          </div>

          {region.county.cd && (
            <div className="county scrnSmll">
              <div className="reg_ttl ">{region.county.name}</div>
            </div>
          )}
          <AiOutlineClose
            className={`close ${region.county.cd ? "exp1" : ""}`}
            onClick={handleClose}
          />
        </div>
      );
      break;
    default:
      rendered = (
        <div
          className={`region ${left ? "" : "rmv_region"}`}
          onClick={handleRgBtn}
        >
          지역선택
        </div>
      );
      break;
  }
  ////////////////////////////////////////////////////////////
  return rendered;
};

export default Region;
